
export default {
  data () {
    return {
      orgItems: [{
        name: 'DiscloseResume',
        title: '领导分工',
        effect: '',
        cols: '6',
        color: 'primary',
        icon: 'mdi-account-multiple-outline'
      }, {
        name: '',
        title: '组织架构',
        effect: '',
        cols: '6',
        color: 'primary',
        icon: 'mdi-rhombus-split'
      }, {
        name: '',
        title: '园区组织体系',
        effect: '',
        cols: '12',
        color: 'primary',
        icon: 'mdi-view-dashboard'
      }],
      orgInfos: [{
        name: '',
        title: '园区公开制度',
        color: 'primary',
        icon: 'mdi-notebook'
      }, {
        name: '',
        title: '园区公开指南',
        color: 'primary',
        icon: 'mdi-book-heart'
      }, {
        name: '',
        title: '园区公开年报',
        color: 'primary',
        icon: 'mdi-file-chart'
      }]
    }
  },
  methods: {
    onOrganizationItem (item) {
      if (!item.name) {
        return
      }
      this.$router.push({ name: item.name })
    },
  }
}
