<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
    />
    <land-section
      id="noticecenter"
      space="40"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="8"
          >
            <v-tabs
              v-model="currTab"
              background-color="transparent"
            >
              <v-tab
                v-for="(tab, tabIndex) in tabList"
                :key="tabIndex"
                :href="`#${tab.name}`"
                class="text-h6"
              >
                {{ tab.title }}
              </v-tab>
              <v-spacer />
              <div class="co-h-full d-flex flex-column justify-center items-center">
                <v-btn
                  icon
                  @click="onTabMore($event, currTab)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-tabs>
            <v-tabs-items v-model="currTab">
              <v-tab-item
                v-for="(tab, tabIndex) in tabList"
                :key="tabIndex"
                :value="tab.name"
              >
                <land-plain-notices
                  :items="tab.items"
                  dense
                  back-color="white"
                  card-color="white"
                  padding="py-4"
                  @click="onNotice($event, tab)"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="hidden-sm-and-down"
          >
            <v-row
              no-gutters
            >
              <v-col
                v-for="(org, orgIndex) in orgItems"
                :key="`org_${orgIndex}`"
                cols="12"
                :md="org.cols"
                style="padding: 0px 6px;"
              >
                <v-btn
                  large
                  :color="org.color"
                  style="width: 100%; margin: 6px 0px;"
                  @click="onOrganizationItem(org)"
                >
                  <v-icon
                    v-if="org.icon"
                    left
                  >
                    {{ org.icon }}
                  </v-icon>
                  {{ org.title }}
                </v-btn>
              </v-col>

              <v-col
                v-for="(info, infoIndex) in orgInfos"
                :key="`info_${infoIndex}`"
                cols="12"
                md="12"
                style="padding: 0px 6px;"
              >
                <v-btn
                  large
                  outlined
                  :color="info.color"
                  style="width: 100%; margin: 6px 0px;"
                  @click="onOrganizationItem(info)"
                >
                  <v-icon
                    v-if="info.icon"
                    left
                  >
                    {{ info.icon }}
                  </v-icon>
                  {{ info.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </land-section>

    <v-parallax
      dark
      :height="finder.height"
      :src="finder.background"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="12"
          >
            <div class="co-flex-row co-items-center co-w-full co-border co-border-white co-round-md co-overflow-hidden">
              <v-menu
                rounded="lg"
                offset-y
              >
                <template #activator="{ attrs, on }">
                  <v-btn
                    tile
                    x-large
                    :color="finder.field.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ finder.field.text }}
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menuItem, menuIndex) in finder.menus"
                    :key="`menu_${menuIndex}`"
                    link
                    @click="onMenuClick($event, menuItem)"
                  >
                    <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <input
                class="co-border-none co-border-transparent co-py2 co-px8 co-text-white"
                style="outline: none; flex: 1;"
                placeholder="输入查询关键字"
              >
              <v-btn
                tile
                x-large
                :color="finder.button.color"
                :width="finder.button.width"
              >
                <v-icon left>
                  {{ finder.button.icon }}
                </v-icon>
                {{ finder.button.text }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <land-contact-way />
  </section>
</template>

<script>
  import mixDiscloseBanner from '@/pages/mixins/disclose/mix.disclose.banner'
  import mixDiscloseBoot from '@/pages/mixins/disclose/mix.disclose.boot'
  import mixDiscloseSide from '@/pages/mixins/disclose/mix.disclose.side'
  import mixDiscloseClassify from '@/pages/mixins/disclose/mix.disclose.classify'
  import mixDiscloseFinder from '@/pages/mixins/disclose/mix.disclose.finder'

  export default {
    metaInfo: { title: '信息公开' },
    mixins: [
      mixDiscloseBanner,
      mixDiscloseBoot,
      mixDiscloseSide,
      mixDiscloseClassify,
      mixDiscloseFinder
    ],
    data () {
      return {
      }
    },
    created () {
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initNoticesParams()
      this.loadNotices()
    },
  }
</script>

<style scoped>
  input::-webkit-input-placeholder {
    color: #EEEEEE;
  }

  input::-moz-placeholder {
    color: #EEEEEE;
  }

  input:-moz-placeholder {
    color: #EEEEEE;
  }

  input:-ms-input-placeholder {
    color: #EEEEEE;
  }
</style>
