
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import notice from '@/api/co.notice'

import mixPreset from '@/pages/mixins/disclose/mix.disclose.preset'

const parts = mixPreset.Parts
const names = mixPreset.Names

export default {
  data () {
    return {
      currTab: names.LASTEST,
      tabList: [
        parts[names.LASTEST],
        parts[names.PARK],
        parts[names.MAJOR_PROJECT],
        parts[names.UNDERSTAND]
      ]
    }
  },
  methods: {
    onTabMore (ev, tabName) {
      let nav = null
      for (const i in this.tabList) {
        const item = this.tabList[i]
        if (item.name === tabName) {
          nav = item
          break
        }
      }

      if (nav) {
        this.toNotices(ev, nav)
      }
    },
    onNotice (ev, navigation) {
      const item = ev.item
      this.toNotice(item, navigation)
    },
    toNotice (item, navigation) {
      const params = {
        name: navigation.name,
        noticeId: item.noticeId,
        type: item.type,
        title: item.title
      }

      const name = mixPreset.DetailName
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    toNotices (ev, navigation) {
      if (!navigation) {
        return
      }

      const params = {
        name: navigation.name,
        title: navigation.title,
        type: navigation.type,
        topicId: navigation.topicId,
        categoryId: navigation.categoryId
      }
      const name = mixPreset.ListName
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    initNoticeParams (obj) {
      const executed = function (res) {
        // console.log('me.dataList: %o', me.dataList)
        if (res.status) {
        }
      }

      obj.params = notice.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        topicId: obj.topicId,
        status: 'publish',
        limit: obj.limit || 6,
        caches: obj.items,
        executed
      })
    },
    initNoticesParams () {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        this.initNoticeParams(item)
      }
    },
    loadNotices (reload = true) {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        item.params.reload = reload
        api.http.getItems(item.params)
      }
    },
  }
}
