
export default {
  data () {
    return {
      clsDisclose: {
        title: '重点领域信息公开',
        buttons: [{
          icon: 'mdi-chevron-right'
        }],
        items: []
      }
    }
  }
}
