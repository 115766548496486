
export default {
  data () {
    return {
      finder: {
        background: 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
        height: 200,
        field: {
          text: '文件标题',
          value: 'title',
          color: 'message'
        },
        menus: [{
          text: '文件标题',
          value: 'title'
        }, {
          text: '文件描述',
          value: 'desc'
        }],
        button: {
          text: '查询',
          icon: 'mdi-magnify',
          color: 'white',
          width: '140'
        }
      }
    }
  },
  methods: {
    onMenuClick (ev, item) {
      const fld = Object.assign({}, this.finder.field, item)
      this.$set(this.finder, 'field', fld)
    }
  }
}
